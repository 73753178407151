<template>
    <div class="Tree">
        <a-drawer v-if="classifyData.length!=0" :title="alertTitle" placement="right" :closable="false" @close="onClose" :visible="visible" width="400">
            <a-form layout="vertical" hideRequiredMark>
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-item label="分类名称">
                            <a-input style="height: 40px;margin-top:5px;" v-model="drawerTitle" placeholder="请输入..."/>
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-item label="标识">
                            <a-input style="height: 40px;margin-top:5px;" v-model="label" placeholder="请输入..."/>
                        </a-form-item>
                    </a-col>
                </a-row>
            </a-form>
            <div class="drawer-bottom">
                <a-radio-button value="default" style="width: 120px;text-align: center;margin-right: 10px" @click="_hide">取消</a-radio-button>
                <a-button @click="_save" type="primary" style="width: 120px;text-align: center">{{indexType=='set'?'修改':'确认'}}</a-button>
            </div>
        </a-drawer>
        <div>
            <div style="box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);display: flex;height: 100%;">
                <div class="classify">
                    <div class="classify-header">分类管理</div>
                    <ul class="classify-list">
                        <li v-for="(item,index) in classifyData" :key="index" @click="_mouseActive(index)" :class="mouseActive==index?'ant-menu-item-selected':''" :style="mouseActive==index?'background:#f8f9fa':''">
                            <div class="border-left" :class="mouseActive==index?'ant-btn-primary':''"></div>
                            <div style="display: flex;justify-content: space-between;align-items: center;width: 100%;">
                                <span>
                                    <a-icon type="appstore" /> <span>{{item.title}}</span>
                                </span>
                                <div v-show="mouseActive==index" style="display: flex;align-items: center;">
                                    <a-icon type="plus-circle" class="icon-btn ant-menu-item-selected" @click="_select(item,[])"/>
                                    <a-icon type="form" class="icon-btn ant-menu-item-selected" @click="_select(item,[index],'setClassif')"/>
                                    <a-popconfirm placement="top" cancelText="取消" okText="确定" :visible="maxClassify==index" @confirm="_removeClassify(item.Id,index)" @cancel="maxClassify=-1">
                                        <template slot="title">
                                            <p>确定删除么？</p>
                                        </template>
                                        <a-icon type="delete" class="icon-btn ant-menu-item-selected" style="margin-left:9px" @click.stop="_maxClassify"/>
                                    </a-popconfirm>
                                </div>
                            </div>
                            <!--<a-dropdown placement="bottomCenter" v-show="mouseActive==index" :trigger="['click']">-->
                                <!--<img src="https://smart-resource.sikegroup.com/saas-more.png" style="width: 25px;height:25px;" alt="">-->
                                <!--<a-menu slot="overlay">-->
                                    <!--<a-menu-item>-->
                                        <!--<span @click="_select(classifyData[mouseActive].title,classifyData[mouseActive].Id,classifyData[mouseActive].Pid,[])">添加</span>-->
                                    <!--</a-menu-item>-->
                                    <!--<a-menu-item>-->
                                        <!--<span @click="_select(item.title,item.Id,item.Pid,[index],'setClassif')">编辑</span>-->
                                    <!--</a-menu-item>-->
                                    <!--<a-menu-item>-->
                                        <!--<a-popconfirm placement="right" trigger="hover" cancelText="取消" okText="确定" @confirm="_removeClassify(item.Id,index)">-->
                                            <!--<template slot="title">-->
                                                <!--<p>确定删除么？</p>-->
                                            <!--</template>-->
                                            <!--<span>删除</span>-->
                                        <!--</a-popconfirm>-->
                                    <!--</a-menu-item>-->
                                <!--</a-menu>-->
                            <!--</a-dropdown>-->
                        </li>
                    </ul>
                    <div style="margin: 20px 15px;">
                        <a-button type="dashed" style="height: 40px" block @click="_select('添加分类')">+ 添加分类</a-button>
                    </div>
                </div>

                <div class="classify classify-item" v-if="classifyData.length!=0">
                    <div class="classify-header">{{classifyData[mouseActive].title}}</div>
                    <div style="position: relative">
                        <ul class="classify-item-list">
                            <template v-for="(item,i) in classifyData[mouseActive].children">
                                <li @mouseenter="_showIndex(i)" @click.stop="_select(item,[i],'set')">
                                    <div class="list-item-content">
                                        <div class="border-left ant-btn-primary"></div>
                                        <span>{{item.title}}</span>
                                        <div v-show="showIndex==i" style="display: flex;align-items: center;">
                                            <a-icon type="plus-circle" class="icon-btn ant-menu-item-selected" @click.stop="_select(item,[i],'add')" />
                                            <a-popconfirm placement="top" cancelText="取消" okText="确定" :visible="popconfirm==i" @confirm="_removeClassify(item.Id,[i])" @cancel="popconfirm=-1">
                                                <template slot="title">
                                                    <p>确定删除么？</p>
                                                </template>
                                                <a-icon type="delete" class="icon-btn ant-menu-item-selected" @click.stop="_popconfirm"/>
                                            </a-popconfirm>
                                        </div>
                                    </div>
                                </li>
                                <template v-for="(tag,j)  in item.children">
                                    <li style="padding-left: 80px" @mouseenter="_showIndex(i+'-'+j)" @click.stop="_select(tag,[i,j],'set')">
                                        <div class="list-item-content">
                                            <span>{{tag.title}}</span>
                                            <div v-show="showIndex==i+'-'+j" style="display: flex;align-items: center;">
                                                <a-icon type="plus-circle" class="icon-btn ant-menu-item-selected" @click.stop="_select(tag,[i,j],'add')"/>
                                                <a-popconfirm placement="top" cancelText="取消" okText="确定" :visible="popconfirm==i+'-'+j" @confirm="_removeClassify(tag.Id,[i,j])" @cancel="popconfirm=-1">
                                                    <template slot="title">
                                                        <p>确定删除么？</p>
                                                    </template>
                                                    <a-icon type="delete" class="icon-btn ant-menu-item-selected" @click.stop="_popconfirm"/>
                                                </a-popconfirm>
                                            </div>
                                        </div>
                                    </li>

                                    <template v-for="(tag2,k)  in tag.children">
                                        <li style="padding-left: 140px" @mouseenter="_showIndex(i+'-'+j+'-'+k)" @click.stop="_select(tag2,[i,j,k],'set')">
                                            <div class="list-item-content">
                                                <span>{{tag2.title}}</span>
                                                <div v-show="showIndex==i+'-'+j+'-'+k" style="display: flex;align-items: center;">
                                                    <a-popconfirm placement="top" cancelText="取消" okText="确定" :visible="popconfirm==i+'-'+j+'-'+k" @confirm="_removeClassify(tag2.Id,[i,j,k])" @cancel="popconfirm=-1">
                                                        <template slot="title">
                                                            <p>确定删除么？</p>
                                                        </template>
                                                            <a-icon type="delete" class="icon-btn ant-menu-item-selected" @click.stop="_popconfirm"/>
                                                    </a-popconfirm>
                                                </div>
                                            </div>
                                        </li>
                                    </template>
                                </template>
                            </template>
                        </ul>
                        <template v-if="classifyData[mouseActive].children.length!=0">
                            <div class="vertical-line"></div>
                            <div class="vertical-line-2"></div>
                            <div class="vertical-line-3"></div>
                        </template>
                    </div>

                    <div class="empty" v-show="classifyData[mouseActive].children.length==0">暂无数据</div>
                    <!--<div class="add-classify ant-btn-primary" @click="_select(classifyData[mouseActive].title,classifyData[mouseActive].Id,classifyData[mouseActive].Pid,[])">+</div>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Tree",
        data(){
            return{
                listData: '',
                mouseActive: 0,
                treeData: [
                    {
                        title: '测试1',
                        key: '0-0',
                        id: '1',
                        slots: {
                            icon: 'smile',
                        },
                        children: [
                            {
                                title: '测试2',
                                key: '0-0-0',
                                id: '2',
                                slots: { icon: 'meh' },
                                children: [
                                    {
                                        title: '测试4',
                                        key: '0-0-0-0',
                                        id: '4'
                                    },
                                    {
                                        title: '测试5',
                                        key: '0-0-0-1',
                                        id: '5'
                                    },
                                ]
                            },
                            {   title: '测试3',
                                key: '0-0-1',
                                id: '3',
                                scopedSlots: { icon: 'custom' },
                                children: [
                                    {
                                        title: '测试4',
                                        key: '0-0-0-0',
                                    },
                                    {
                                        title: '测试5',
                                        key: '0-0-0-1',
                                    },
                                ]
                            },
                        ],
                    }
                ],
                visible: false,
                tabActive: 0,
                drawerTitle: '',
                infoMessage: '',
                showInfoMsg: false,
                childrenName:'',
                parentId: '',
                selectData: '',
                classifyData: [],
                alertTitle: '',
                id: '',
                pid: '',
                indexList: [],
                showIndex: -1,
                indexType: '',
                popconfirm: -1,
                maxClassify: -1,
                label: ''
            }
        },
        created(){
            this.$message.loading('加载中...');
            this.$axios.get(1200, {
            },res => {
                if(res.data.code===1){
                    this.listData = res.data.data;
                    this.classifyData = res.data.data;
                }
                this.$message.destroy();
            });
        },
        methods:{
            _popconfirm(){
                this.popconfirm = this.showIndex;
            },
            _showIndex(i){
                this.showIndex = i;
                this.popconfirm = -1;
            },
            _removeClassify(id,i){
                let self  = this;
                this.$axios.post(1211, {Id: id,CreateUserId:this.__USER__.uid},res => {
                    if(res.data.code===1){
                        if(Array.isArray(i)){
                            if(i.length==1){
                                self.classifyData[[self.mouseActive]].children.splice(i[0],1)
                            }else if(i.length==2){
                                self.classifyData[[self.mouseActive]].children[i[0]].children.splice(i[1],1)
                            }else if(i.length==3){
                                self.classifyData[[self.mouseActive]].children[i[0]].children[i[1]].children.splice(i[2],1)
                            }
                        }else{
                            self.classifyData.splice(i,1);
                        }
                        self.$message.success('删除成功');
                    }else{
                        self.$message.error(res.data.msg);
                    }

                });
            },
            _maxClassify(){
                this.maxClassify = this.mouseActive;
            },
            _mouseActive(i){
                this.mouseActive = i;
                this.maxClassify = -1;
            },
            _save(){
                let self = this;
                if(this.indexType == 'set' || this.indexType == 'setClassif'){
                    self.$axios.post(1212,{
                        Id: this.id,
                        Name: this.drawerTitle,
                        Label: this.label,
                        CreateUserId: this.__USER__.uid
                    },res=>{
                        if(res.data.code==1){
                            self.$message.success('操作成功');
                            if(self.indexList.length==0){
                                self.classifyData[self.mouseActive].title = this.drawerTitle;
                                self.classifyData[self.mouseActive].Label = this.label;
                            }else if(self.indexList.length==1){
                                if(this.indexType=='setClassif'){
                                    self.classifyData[self.mouseActive].title = this.drawerTitle;
                                    self.classifyData[self.mouseActive].Label = this.label;
                                }else{
                                    self.classifyData[self.mouseActive].children[self.indexList[0]].title = this.drawerTitle;
                                    self.classifyData[self.mouseActive].children[self.indexList[0]].Label = this.label;
                                }
                            }else if(self.indexList.length==2){
                                self.classifyData[self.mouseActive].children[self.indexList[0]].children[self.indexList[1]].title = this.drawerTitle;
                                self.classifyData[self.mouseActive].children[self.indexList[0]].children[self.indexList[1]].Label = this.label;
                            }else if(self.indexList.length==3){
                                self.classifyData[self.mouseActive].children[self.indexList[0]].children[self.indexList[1]].children[self.indexList[2]].title = this.drawerTitle;
                                self.classifyData[self.mouseActive].children[self.indexList[0]].children[self.indexList[1]].children[self.indexList[2]].Label = this.label;
                            }
                            self.visible = false;
                            self.id = '';
                            self.pid = '';
                            self.indexList = '';
                            self.drawerTitle = '';
                            self.childrenName = '';
                            self.label = '';
                        }else{
                            self.$message.error(res.data.msg);
                        }
                    })
                }else{
                    self.$axios.post(1202, {
                        Pid: this.id?this.id:'',
                        Name: this.drawerTitle,
                        Label: this.label,
                        CreateUserId: this.__USER__.uid,
                        CreateUserName: this.__USER__.name,
                        Sort: 0,
                        ComId: '',
                    },res => {
                        if(res.data.code==1){
                            self.$message.success('操作成功');
                            let item = {Pid: this.id,Label:this.label,title: self.drawerTitle,Id:res.data.Id,children:[]};
                            if(self.indexList==undefined||self.indexList==null){
                                self.classifyData.push(item);
                            }else{
                                if(self.indexList.length==0){
                                    self.classifyData[self.mouseActive].children.push(item);
                                }else if(self.indexList.length==1){
                                    self.classifyData[self.mouseActive].children[self.indexList[0]].children.push(item);
                                }else if(self.indexList.length==2){
                                    self.classifyData[self.mouseActive].children[self.indexList[0]].children[self.indexList[1]].children.push(item);
                                }
                            }
                            self.visible = false;
                            self.id = '';
                            self.pid = '';
                            self.indexList = '';
                            self.drawerTitle = '';
                            self.childrenName = '';
                            self.label = '';
                        }else{
                            self.$message.error(res.data.msg);
                        }
                    });
                }
            },
            _hide(){
                this.visible = false;
                this.id = '';
                this.pid = '';
                this.indexList = '';
                this.drawerTitle = '';
                this.childrenName = '';
                this.label = '';
            },
            _tabChange(activeKey){
                this.$axios.get(1200, {
                    pid: this.listData[activeKey].Id
                },res => {
                    if(res.data.code===1){
                        this.treeData = res.data.data;
                        this.showInfoMsg = false;
                    }else{
                        this.treeData = [];
                        this.showInfoMsg = true;
                        this.infoMessage = res.data.msg;
                    }
                });
            },
            _select(item,list,type){
                if(type=='set'||type=='setClassif'){
                    this.drawerTitle = item.title;
                    this.label = item.Label;
                }
                this.alertTitle = item.title;
                this.id = item.Id;
                this.pid = item.Pid;
                this.visible = true;
                this.indexList = list;
                this.indexType = type;
            },
            onClose() {
                this.visible = false;
                this.alertTitle = '';
                this.drawerTitle = '';
                this.label = '';
                this.id = '';
                this.pid = '';
            },
        }
    }
</script>

<style scoped lang="less">
    /*修改滚动条样式*/
    div::-webkit-scrollbar{
        width:6px;
        height:6px;
        /**/
    }
    div::-webkit-scrollbar-track{
        background: #F8F9FA;
        border-radius:2px;
    }
    div::-webkit-scrollbar-thumb{
        background: #bfbfbf;
        border-radius:10px;
    }
    div::-webkit-scrollbar-thumb:hover{
        background: #333;
    }
    div::-webkit-scrollbar-corner{
        background: #179a16;
    }
    .Tree{
        display: flex;
        padding-top: 0;
        height: 100%;
    }
    .icon-btn{
        cursor: pointer;
        margin-left:10px;
        font-size: 18px;
    }
    .classify{
        width: 350px;
        overflow-y: scroll;
        background: #fff;
        .classify-header{
            height: 60px;
            line-height: 60px;
            padding-left: 30px;
            border-bottom: 1px solid #F3F3F3;
            font-size: 18px;
        }
        .classify-list{
            margin-top: 10px;
            li{
                height: 50px;
                line-height: 50px;
                padding: 0 20px;
                font-size: 16px;
                border-bottom: 1px solid #F3F3F3;
                cursor: pointer;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                span{
                    display: inline-block;
                    padding-left: 10px;
                }
                .border-left{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 4px;
                    height: 100%;
                }
            }
        }
    }
    .classify-item{
        width: 450px;
        overflow-y: scroll;
        background: #F8F9FA;
        position: relative;
        .classify-header{
            width: 100%;
            border-bottom: 1px solid #E0E1E2;
            background: #F8F9FA;
        }
        .classify-item-list{
            margin-top: 10px;
            width: 100%;
            position: relative;
            z-index: 100;
            li{
                width: 100%;
                height: 45px;
                padding: 0 30px 0 30px;
                margin-bottom: 10px;
                cursor: pointer;
                .list-item-content{
                    position: relative;
                    width: 100%;
                    height: 45px;
                    line-height: 45px;
                    padding: 0 20px;
                    padding-right: 10px;
                    border: 1px solid #E0E1E2;
                    border-radius: 3px;
                    background: #fff;
                    font-size: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .border-left{
                        position: absolute;
                        top: 50%;
                        left: -1px;
                        transform: translateY(-50%);
                        width: 2px;
                        height: 100%;
                        border-top-left-radius: 10px;
                        border-bottom-left-radius: 10px;
                    }
                }
            }
        }
        .vertical-line{
            position: absolute;
            top: 0;
            left: 50px;
            width: 2px;
            min-height: 100%;
            background: #E0E1E2;
        }
        .vertical-line-2{
            position: absolute;
            top: 0;
            left: 110px;
            width: 2px;
            min-height: 100%;
            background: #E0E1E2;
        }
        .vertical-line-3{
            position: absolute;
            top: 0;
            left: 170px;
            width: 2px;
            min-height: 100%;
            background: #E0E1E2;
        }
    }
    .drawer-bottom{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-top: 1px solid #e9e9e9;
        padding: 10px 16px;
        background: #fff;
        text-align: left;
    }
    .empty{
        font-size: 16px;
        color: #aaa;
        text-align: center;
        padding: .2rem;
        margin-top: 15px;
    }
    .add-classify{
        position: absolute;
        bottom: 20px;
        right: 20px;
        width: 50px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        border-radius: 50%;
        font-size: 24px;
        font-weight: bold;
        color: #fff;
        cursor: pointer;
        z-index: 1000
    }
    .flip-list-move {
        transition: transform 1s;
    }
</style>